<template>
	<div
		data-component="simulator-button"
		:data-icon="icon"
		:data-disabled="isDisabled"
		:data-size="size"
		:data-colour="colour"
		:data-open="isOpen"
	>
		<icon
			:icon="icon"
			:colour="colour"
		/>
		<p
			data-element="text"
			v-if="text"
		>
			{{ text }}
		</p>
	</div>
</template>

<script>

	import Icon  from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		props: {
			icon: {
				type: String,
				required: true
			},
			size: {
				type: String,
				default: 'md'
			},
			colour: {
				type: String,
				default: 'white'
			},
			isDisabled: {
				type: Boolean,
				default: false
			},
			text: {
				type: String,
				default: undefined
			},
			isOpen: {
				type: Boolean,
				default: false
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='simulator-button'] {
		display:flex;
		flex-direction:column;
		align-items:center;
		justify-content:center;
		margin:0 4px 0 0;
		border-radius:4px;
		cursor:pointer;
		user-select:none;
		transition:background 0.2s ease-in;
		// outline:1px solid magenta;
		[data-component='icon'] {
			flex-shrink:0;
		}
		[data-element='text'] {
			font-size:rem(10);
			font-weight:700;
			opacity:0;
			transition:all 0.2s ease-in;
		}
		&[data-open],
		&:hover {
			[data-element='text'] {
				opacity:1;
			}
		}
		&[data-disabled] {
			opacity:0.2;
			cursor:default;
		}
		&[data-colour='white'] {
			[data-element='text'] {
				color:$c-white;
			}
			&[data-open],
			&:hover {
				background-color:rgba(255,255,255,0.2);
			}
		}
		&[data-colour='blue'] {
			[data-element='text'] {
				color:$c-brand-blue;
			}
		}
		&[data-size='xs'] {
			width:rem(28); height:rem(28);
			[data-component='icon'] {
				width:rem(26);
				height:rem(26);
			}
		}
		&[data-size='md'] {
			width:rem(48); height:rem(48);
			[data-component='icon'] {
				width:rem(28);
				height:rem(28);
			}
		}
	}
</style>
