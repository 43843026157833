<template>
	<div
		data-component="controls"
		v-click-outside="clickOutside"
	>
		<div
			v-for="(controlColumn, columnKey) in getControls"
			:key="columnKey"
			data-element="control-column"
		>
			<div
				v-for="(control, controlKey) in controlColumn"
				:key="controlKey"
				data-element="control"
			>
				<simulator-button
					:icon="control.icon"
					@click.native="clickControl({ control })"
					:isDisabled="control.isDisabled"
					colour="blue"
					:text="control.text"
					size="md"
					:isOpen="isOpen({ control })"
				/>
				<div
					v-if="hasMenu({ control })"
					data-element="menu"
					:data-open="isOpen({ control })"
				>
					<div
						v-for="(menuControl, menuControlKey) in control.menu"
						:key="menuControlKey"
						data-element="menu-control"
						:data-disabled="menuControl.isDisabled"
						@click="clickMenuControl({ menuControl })"
					>
						{{ menuControl.text }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import SimulatorButton from '@/components/simulator/ui/SimulatorButton';

	export default {
		name: 'Controls',
		components: {
			SimulatorButton
		},
		props: {
			isRemote: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			getControls () {
				return this.$store.getters['simulatorControls/getSimulatorControls']({
					isRemote: this.isRemote
				});
			}
		},
		methods: {
			hasMenu ({ control }) {
				return this.$store.getters['simulatorControls/getControlHasMenu']({ control });
			},
			isOpen ({ control }) {
				return this.$store.getters['simulatorControls/getControlMenuIsOpen']({ control });
			},
			clickControl ({ control }) {
				this.$store.dispatch('simulatorControls/clickControl', {
					control
				});
			},
			clickMenuControl ({ menuControl }) {
				this.$store.dispatch('simulatorControls/clickControl', {
					control: menuControl
				});
			},
			clickOutside () {
				this.$store.commit('simulatorControls/closeAllMenus');
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-component='controls'] {
		display:flex;
		[data-element='control-column'] {
			display:flex;
			flex-direction:column;
			justify-content:center;
			[data-element='control'] {
				position: relative;
				[data-component='simulator-button'] {
					margin:rem(1);
				}
				[data-element='menu'] {
					position:absolute;
					z-index:20;
					bottom:rem(48);
					margin:0;
					padding:rem(4);
					display:none;
					background-color:$c-white;
					border-radius:4px;
					box-shadow:0 1px 2px 0 rgba(0, 0, 0, 0.72);
					&[data-open] {
						display:block;
					}
					[data-element='menu-control'] {
						margin:rem(2);
						padding:rem(4) rem(6);
						font-size:rem(14);
						line-height:1;
						font-weight:700;
						color:$c-brand-blue;
						border-radius:4px;
						cursor:pointer;
						user-select:none;
						white-space:nowrap;
						&:hover {
							background-color:$c-brand-blue-lighter-4;
						}
						&[data-disabled] {
							opacity:0.2;
						}
					}
				}
			}
		}
	}

</style>
